import {isValidArchive} from '@/lib/utils/archives';
import {useRouter} from 'next/router';
import {getWordpressPage} from '@/features/archiveAsAProduct/utils/api/wordpress';
import {WordpressPageDto} from '@/features/archiveAsAProduct/types/dto/page';
import {HttpResponse} from '@/lib/types/http';

const Custom404 = () => {
  const router = useRouter();
  const firstUrlSlug = router.asPath.match(/[^/]+/g)?.[0];
  const archive = isValidArchive(firstUrlSlug) ? firstUrlSlug : 'icpsr';
  const slug = 'not-found';

  const fetchPage = async () => {
    try {
      const pageResult: HttpResponse<WordpressPageDto> = await getWordpressPage(
        archive,
        slug
      );
      pageResult.data && router.push('/' + archive + '/' + slug);
    } catch (e) {
      console.log('Error fetching WordPress page');
    }
  };
  fetchPage();
};

export default Custom404;
